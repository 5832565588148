import {
  AnonymizeCustomerMutation,
  DeactivateCustomerMutation,
  GetCustomerQuery,
  GetCustomersQuery,
} from "../../graphql/customer"
import { CustomerAction, CustomerGetter, CustomerMutation } from "../types"
import {
  deliveryCarrierIconSrc,
  formatDate,
  formatDatetime,
  formatFullName,
  formatPrice,
  formatSizes,
  handleGraphQLErrors,
  paymentServiceProviderIconSrc,
  shortUuid,
} from "../../utils"
import http from "../../http"

const Status = {
  Loading: "loading",
  Failed: "failed",
  Succeed: "succeed",
}

const resultsPerPage = 15

export default {
  state: {
    customers: [],
    customer: null,
    status: null,
    page: 1,
    totalPages: null,
    filters: {},
    errors: {},
    showDeactivationModal: false,
    showAnonymizationModal: false,
  },
  mutations: {
    [CustomerMutation.GetAllSucceed]: (state, payload) => {
      state.customers = payload
      state.status = Status.Succeed
    },
    [CustomerMutation.GetOneSucceed]: (state, payload) => {
      state.customer = payload
      state.status = Status.Succeed
    },
    [CustomerMutation.Failed]: (state) => state.status = Status.Failed,
    [CustomerMutation.Loading]: (state) => state.status = Status.Loading,
    [CustomerMutation.ApplyFilters]: (state, payload) => state.filters = payload,
    [CustomerMutation.Page]: (state, payload) => state.page = payload,
    [CustomerMutation.TotalPages]: (state, payload) => state.totalPages = payload,
    [CustomerMutation.OpenDeactivationModal]: (state) => state.showDeactivationModal = true,
    [CustomerMutation.CloseDeactivationModal]: (state) => state.showDeactivationModal = false,
    [CustomerMutation.CloseAnonymizationModal]: (state) => state.showAnonymizationModal = false,
    [CustomerMutation.OpenAnonymizationModal]: (state) => state.showAnonymizationModal = true,
    [CustomerMutation.Errors]: (state, error) => {
      state.errors = error
    },
  },
  actions: {
    [CustomerAction.GetAll]: async ({ state, commit }, page) => {
      commit(CustomerMutation.Loading)

      page = page || state.page || 1
      http.post('/graphql', JSON.stringify({
        query: GetCustomersQuery,
        variables: {
          page: page,
          limit: resultsPerPage,
          filters: state.filters,
        },
      }))
        .then((res) => {
          commit(CustomerMutation.Page, page)
          commit(CustomerMutation.GetAllSucceed, res.data.data.customers.page)
          commit(CustomerMutation.TotalPages, res.data.data.customers.totalNumberOfPages)
        })
        .catch(() => commit(CustomerMutation.Failed))
    },
    [CustomerAction.GetOne]: async ({ commit }, payload) => {
      commit(CustomerMutation.Loading)

      http.post('/graphql', JSON.stringify({
        query: GetCustomerQuery,
        variables: { id: payload },
      }))
        .then((res) => commit(CustomerMutation.GetOneSucceed, res.data.data.customer))
        .catch(() => commit(CustomerMutation.Failed))
    },
    [CustomerAction.ApplyFilters]: async ({ commit, dispatch }, payload) => {
      commit(CustomerMutation.ApplyFilters, payload)
      commit(CustomerMutation.Page, 1)
      dispatch(CustomerAction.GetAll)
    },
    [CustomerAction.Deactivate]: async ({ commit, dispatch }, payload) => {
      commit(CustomerMutation.Loading)
      http.post('/graphql', JSON.stringify({
        query: DeactivateCustomerMutation,
        variables: {
          customerId: payload,
        },
      }))
        .then((res) => {
          const errors = handleGraphQLErrors(res)
          if (errors !== null) {
            commit(CustomerMutation.Errors, errors)

            return
          }

          commit(CustomerMutation.CloseDeactivationModal)
          dispatch(CustomerAction.GetOne, payload)
        })
        .catch(() => commit(CustomerMutation.Errors, { internal: true } ))
    },
    [CustomerAction.Anonymize]: async ({ commit, dispatch }, payload) => {
      commit(CustomerMutation.Loading)
      http.post('/graphql', JSON.stringify({
        query: AnonymizeCustomerMutation,
        variables: {
          customerId: payload,
        },
      }))
        .then((res) => {
          const errors = handleGraphQLErrors(res)
          if (errors !== null) {
            commit(CustomerMutation.Errors, errors)

            return
          }

          commit(CustomerMutation.CloseAnonymizationModal)
          dispatch(CustomerAction.GetOne, payload)
        })
        .catch(() => commit(CustomerMutation.Errors, { internal: true } ))
    },
  },

  getters: {
    [CustomerGetter.List]: (state) => {
      return state.customers.map((customer) => {
        return {
          ...customer,
          fullName: formatFullName(customer),
          shortId: shortUuid(customer.id),
          createdAt: formatDatetime(customer.createdAt),
        }
      })
    },
    [CustomerGetter.Details]: (state) => {
      if (!state.customer) return null

      const postalAddressBookEntries = state.customer.postalAddressBookEntries.map(postalAddressBookEntry => {
        postalAddressBookEntry.createdAt = formatDatetime(postalAddressBookEntry.createdAt)
        postalAddressBookEntry.isDefault =
            postalAddressBookEntry.id === state.customer.defaultPostalAddressBookEntry?.id

        return postalAddressBookEntry
      })

      const orders = state.customer.orders.page.map(order => {
        order.createdAt = formatDate(order.createdAt)
        order.totalAmount = formatPrice(order.totalAmount)
        order.paymentServiceProviderIconSrc = paymentServiceProviderIconSrc(order.paymentServiceProvider)
        order.shippingInfo.deliveryCarrierIconSrc = deliveryCarrierIconSrc(order.shippingInfo.deliveryCarrier)
        order.coupons = order.discounts.filter(discount => discount.type === 'COUPON').map(discount => {
          return { id: discount.id, code: discount.code }
        })
        order.giftCards = order.discounts.filter(discount => discount.type === 'GIFT_CARD').map(discount => {
          return { id: discount.id, code: discount.code }
        })

        return order
      })

      return {
        ...state.customer,
        shortId: shortUuid(state.customer.id),
        birthDate: formatDate(state.customer.birthDate),
        createdAt: formatDatetime(state.customer.createdAt),
        topSizes: formatSizes(state.customer.sizes
          .filter(size => size.startsWith('top-'))
          .map(size => size.split('-', 2)[1])
        ),
        waistSizes: formatSizes(state.customer.sizes
          .filter(size => size.startsWith('waist-'))
          .map(size => size.split('-', 2)[1])
        ),
        headSizes: formatSizes(state.customer.sizes
          .filter(size => size.startsWith('head-'))
          .map(size => size.split('-', 2)[1])
        ),
        shoesSizes: formatSizes(state.customer.sizes
          .filter(size => size.startsWith('shoe-'))
          .map(size => size.split('-', 2)[1])
        ),
        updatedAt: formatDatetime(state.customer.updatedAt),
        connectionAt: formatDatetime(state.customer.connectionAt),
        anonymizedAt: formatDatetime(state.customer.anonymizedAt, true, null),
        newsletterSubscriptionIntendedAt: formatDatetime(state.customer.newsletterSubscriptionIntendedAt),
        activeAt: formatDatetime(state.customer.activeAt),
        postalAddressBookEntries,
        orders,
      }
    },
    [CustomerGetter.IsLoading]: (state) => state.status === Status.Loading,
    [CustomerGetter.HasError]: (state) => state.status === Status.Failed,
    [CustomerGetter.Page]: (state) => state.page,
    [CustomerGetter.TotalPages]: (state) => state.totalPages,
    [CustomerGetter.Filters]: (state) => state.filters,
    [CustomerGetter.Errors]: (state) => state.errors,
    [CustomerGetter.IsDeactivationModalOpened]: (state) => state.showDeactivationModal,
    [CustomerGetter.IsAnonymizationModalOpened]: (state) => state.showAnonymizationModal,
  },
}
