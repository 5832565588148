export const GetOrderRefunds = `
  query GetOrderRefunds($orderId: OrderId!, $page: Int!) {
    orderRefunds (filters: { orderId: $orderId }) {
      totalNumberOfPages
      page(page: $page) {
        createdAt
        shippingRefund { amount currency }
        qualityControlRefund { amount currency }
        refundedAmount { amount currency }
        productRefunds { amount currency }
        orderItemRefunds {
          orderItem {
            product {
              name
            }
          }
          refundedAmount { amount currency }
        }
        orderShipmentRefunds {
          orderShipment {
            deliveryCarrier
          }
          refundedAmount { amount currency }
        }
        reason
        refundedBy
      }
    }
  }
`
