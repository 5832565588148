<template>
  <Page :loading="loading" :error="error" type="list">
    <template v-slot:title>Commandes</template>
    <template v-slot:no-reload>
      <v-form>
        <v-row>
          <v-col cols="12" md="3">
            <v-text-field v-model="filters.reference" label="Référence" clearable></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-select
                :items="status"
                v-model="filters.status"
                label="Status"
                clearable
            ></v-select>
          </v-col>
          <v-col cols="12" md="6" align="right">
            <v-btn
                fab
                color="primary"
                @click="refresh"
                size="small">
              <v-icon dark>
                mdi-refresh
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template v-if="orders.length">
        <v-simple-table>
          <thead>
          <tr>
            <th>Commande</th>
            <th>Date</th>
            <th>État</th>
            <th>Client</th>
            <th>Livrée à</th>
            <th class="text-center">Mode</th>
            <th class="text-center">Type de livraison</th>
            <th class="text-center">Transporteur</th>
            <th class="text-right">Total</th>
            <th class="text-right">Payé</th>
            <th class="text-right">Remboursé</th>
            <th class="text-right">-</th>
          </tr>
          </thead>
          <tbody>
          <tr
              v-for="order in orders"
              :key="order.id"
              tabIndex="0"
              @keypress.enter="() => onOrderClick(order.id)"
          >
            <td>
              <router-link :to="{ name: 'orderDetails', params: { id: order.id } }">
                #{{ order.reference }}
              </router-link>
            </td>
            <td>{{ order.createdAt }}</td>
            <td>
              <StatusChip :status="order.status" />
            </td>
            <td>
              <router-link :to="{ name: 'customerDetails', params: { id: order.customer.id } }">
                {{ order.customerName || order.customer.emailAddress }}
              </router-link>
            </td>
            <td>{{ getCountryFlag(order.countryCode) }} {{ order.shippingInfo.deliveryAddress }}</td>
            <td class="text-center">
              <v-chip
                label
                class="text-uppercase"
                :color="getDeliveryModeColor(order.shippingInfo.deliveryMethod)"
              >
                {{ order.shippingInfo.deliveryMethod }}
              </v-chip>
            </td>
            <td class="text-center">
              <v-chip
                label
                class="text-uppercase"
                :color="getDeliveryTypeColor(order.shippingInfo.deliveryType)"
              >
                {{ order.shippingInfo.deliveryType }}
              </v-chip>
            </td>
            <td class="text-center">
              <img
                  :src="order.shippingInfo.deliveryCarrierIconSrc"
                  :alt="order.shippingInfo.deliveryCarrier"
                  :title="order.shippingInfo.deliveryCarrier"
                  width="32"
                  height="32"
              />
            </td>
            <td class="text-right">{{ order.totalAmount }}</td>
            <td class="text-right">{{ order.paidAmount }}</td>
            <td class="text-right">{{ order.refundedAmount }}</td>
            <td class="text-right">
              <img
                :src="order.paymentServiceProviderIconSrc"
                :alt="order.paymentServiceProvider"
                :title="order.paymentServiceProvider"
              />
            </td>
          </tr>
          </tbody>
        </v-simple-table>
        <div class="text-center  mt-8" v-if="nbPages">
          <v-pagination
              v-model="page"
              :length="nbPages"
              :total-visible="8"
              rounded="circle"
          ></v-pagination>
        </div>
      </template>
  </Page>
</template>

<script>
import { alpha3toAlpha2CountryCode, countryCodeEmoji } from "../../helpers/countries"
import { getDeliveryModeColor, getDeliveryTypeColor } from "../../components/Order/orderChips"
import { OrderAction, OrderGetter } from "../../store/types"
import { mapGetters } from 'vuex'
import Page from "../Layout/Page"
import StatusChip from "./StatusChip"

let debounceTimerId

export default {
  components: {
    Page,
    StatusChip,
  },
  data () {
    return {
      status: [
        'COMPLETED',
        'PAID',
        'CHECKOUT',
        'ABANDONED',
      ],
      filters: this.$store.getters[OrderGetter.Filters],
    }
  },
  mounted() {
    this.$store.dispatch(OrderAction.GetAll)
  },

  computed: {
    ...mapGetters({
      orders: OrderGetter.List,
      loading: OrderGetter.IsLoading,
      error: OrderGetter.HasError,
      nbPages: OrderGetter.TotalPages,
    }),
    page: {
      get() { return this.$store.getters[OrderGetter.Page] },
      set(value) { this.$store.dispatch(OrderAction.GetAll, value) },
    },
  },

  methods: {
    getDeliveryTypeColor(status) {
      return getDeliveryTypeColor(status)
    },
    getDeliveryModeColor(status) {
      return getDeliveryModeColor(status)
    },
    onOrderClick(id) {
      this.$router.push({ name: "orderDetails", params: { id } })
    },
    refresh() {
      this.$store.dispatch(OrderAction.GetAll)
    },
    getCountryFlag(countryCode) {
      return countryCodeEmoji(alpha3toAlpha2CountryCode(countryCode))
    },
  },

  watch: {
    "filters.reference"() {
      clearTimeout(debounceTimerId)
      debounceTimerId = setTimeout(() => {
        this.$store.dispatch(OrderAction.ApplyFilters, this.filters)
      }, 400)
    },
    "filters.status"() {
      this.$store.dispatch(OrderAction.ApplyFilters, this.filters)
    },
  },
}
</script>
