export const GetCustomerQuery = `
  query GetCustomer($id: CustomerId!, $pageOrders: Int! = 1)  {
    customer (id: $id) {
      id
      firstName
      lastName
      pseudo
      emailAddress
      birthDate
      createdAt
      sizes
      locale
      updatedAt
      connectionAt
      activeAt
      deactivated
      anonymizedAt
      countryCode
      defaultPostalAddressBookEntry {
        id
      }
      postalAddressBookEntries {
        id
        createdAt
        postalAddress {
          firstName
          lastName
          companyName
          countryCode
          addressLine1
          addressLine2
          postalCode
          cityName
          phoneNumber
        }
      }
      orders {
        page(page: $pageOrders) {
          id
          reference
          createdAt
          status
          paymentServiceProvider
          productCount
          totalAmount { amount currency }
          shippingInfo {
            deliveryCarrier
          }
          discounts {
            id
            code
            type
          }
        }
      }
      newsletterSubscriptionIntendedAt
    }
  }
`

export const GetCustomersQuery = `
  query GetCustomers($page: Int!, $limit: Int!, $filters: CustomerFiltersInput) {
    customers(limit: $limit, filters: $filters) {
      totalNumberOfPages
      page(page: $page) {
        id
        firstName
        lastName
        emailAddress
        createdAt
        deactivated
        anonymizedAt
      }
    }
  }
`

export const DeactivateCustomerMutation = `
  mutation Deactivate(
    $customerId: CustomerId!
  ){
    deactivateACustomer(id: $customerId){
        firstName
        lastName
    }
  }
`

export const AnonymizeCustomerMutation = `
  mutation Anonymize(
    $customerId: CustomerId!
  ){
    anonymizeACustomer(id: $customerId){
        firstName
        lastName
    }
  }
`
