export const GetOrderQuery = `
  query GetOrder($id: OrderId!)  {
    order (id: $id) {
      id
      reference
      createdAt
      paidAt
      status
      isRefundable
      paidAt
      updatedAt
      paymentServiceProvider
      paymentId
      parcels {
        items {
          orderItem {
            id
          }
          returnEligibility {
            eligible
            dateLimit
            ineligibilityReason
          }
        }
      }
      orderItems {
        id
        product {
          reference
          variantId
          sku
          slug
          condition
          name
          size
          image
          store {
            id
            name
            country
          }
        }
        customization {
          label
          value
        }
        amount { amount currency }
        paidAmount { amount currency }
        reductionAmount { amount currency }
        refundedAmount { amount currency }
        refundableAmount { amount currency }
      }
      orderShipments {
        id
        deliveryMethod
        deliveryType
        deliveryCarrier
        orderItemIds
        amount { amount currency }
        paidAmount { amount currency }
        reductionAmount { amount currency }
        refundedAmount { amount currency }
        refundableAmount { amount currency }
        sender {
          name
          country
        }
      }
      discounts {
        id
        code
        type
        amount { amount currency }
      }
      totalAmount { amount currency }
      totalAmountDetail {
        productAmount { amount currency }
        shippingAmount { amount currency }
        qualityControlAmount { amount currency }
      }
      reductionAmount { amount currency }
      reductionAmountDetail {
        shippingAmount { amount currency }
        qualityControlAmount { amount currency }
      }
      paidAmount { amount currency }
      paidAmountDetail {
        shippingAmount { amount currency }
        qualityControlAmount { amount currency }
      }
      refundableAmountDetail {
        shippingAmount { amount currency }
        qualityControlAmount { amount currency }
      }
      refundedAmount { amount currency }
      refundedAmountDetail {
        shippingAmount { amount currency }
        qualityControlAmount { amount currency }
      }
      contactAddress {
        firstName
        lastName
        companyName
        countryCode
        countryCodeAlpha2
        addressLine1
        addressLine2
        postalCode
        cityName
        phoneNumber
      }
      shippingInfo {
        deliveryMethod
        deliveryType
        deliveryCarrier
        deliveryAddress {
          firstName
          lastName
          companyName
          countryCode
          addressLine1
          addressLine2
          postalCode
          cityName
          phoneNumber
          servicePointId
        }
      }
      customer {
        id
        firstName
        lastName
        emailAddress
      }
      context {
        libraryName
        libraryVersion
      }
    }
  }
`

export const GetOrdersQuery = `
  query GetOrders($page: Int!, $limit: Int!, $filters: OrderFiltersInput) {
    orders (limit: $limit, filters: $filters) {
      totalNumberOfPages
      page(page: $page) {
        id
        reference
        createdAt
        status
        paymentServiceProvider
        totalAmount { amount currency }
        paidAmount { amount currency }
        refundedAmount { amount currency } 
        shippingInfo {
          deliveryAddress {
            firstName
            lastName
            companyName
            countryCode
            addressLine1
            addressLine2
            postalCode
            cityName
            phoneNumber
          }
          deliveryMethod
          deliveryType
          deliveryCarrier
        }
        customer {
          id
          firstName
          lastName
          emailAddress
        }
      }
    }
  }
`

export const RefundOrderMutation = `
  mutation Refund(
    $orderId: OrderId!,
    $itemRefunds: [OrderItemRefundInput!],
    $shipmentRefunds: [OrderShipmentRefundInput!],
    $qualityControlRefund: MoneyInput,
    $reason: RefundReason!,
    $note: String!
  ) {
    refund (
      orderId: $orderId,
      itemRefunds: $itemRefunds,
      shipmentRefunds: $shipmentRefunds,
      qualityControlRefund: $qualityControlRefund,
      reason: $reason,
      note: $note
    ) {
      id
    }
  }
`
